<template>
	<div class="root">
		<Head></Head>
		<Notice></Notice>
		<div class="head">
			<div class="headText">
				<div class="text1">明主自由小组</div>
				<div class="text2">网络服务状态</div>
			</div>
		</div>
		<div class="body">
			<div class="text1">
				<div style="margin-left: 100px;display: flex;flex-direction: row;">
					<img v-if="datacenterStatus&&gitStatus" style="width: 28px;height: 20px;margin-right: 10px;" src="../../public/icon/hook.svg"/>
					<img v-else style="width: 28px;height: 28px;margin-right: 10px;" src="../../public/icon/sigh.png" />
					<div style="font-size: 18px;white-space: nowrap;">{{datacenterStatus&&gitStatus?'各项服务运行正常。':'某些服务项有些问题'}}</div>
				</div>
			</div>
			<div class="text2" style="margin-top: 30px;">
				<div class="round" v-if="datacenterStatus"></div>
				<div class="round" style="background-color: #cc655a;" v-else></div>
				<div style="margin-left: 20px;font-size: 18px;white-space: nowrap;">明主自由数据中心</div>
			</div>
			<div class="text2" style="margin-bottom: 100px;">
				<div class="round" v-if="gitStatus"></div>
				<div class="round" style="background-color: #cc655a;" v-else></div>
				<div style="margin-left: 20px;font-size: 18px;white-space: nowrap;">Mingzhuziyou Git</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Head from '../components/Head.vue'
	import Notice from '../components/Notice.vue'
	import Footer from '../components/Footer.vue'
	export default {
		name: 'Status',
		components:{ Head,Notice,Footer },
		data() {
			return {
				datacenterStatus: true,
				gitStatus: true
			}
		},
		created() {
			this.init()
		},
		methods: {
			async init() {
				try {
					let res = await this.$req.get('/networkStatusCheck')
					this.datacenterStatus = (res.data.datacenterStatus==0)?true:false
					this.gitStatus = (res.data.gitStatus==0)?true:false
				}catch(err){
					this.datacenterStatus = false
					this.gitStatus = false
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.root {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.head {
		background-image: linear-gradient(-20deg, #2b5876 0%, #4e4376 100%);
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		text-align: left;
		color: white;
		height: 250px;
		width: 100%;
		padding-left: 250px;

		.headText {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		.text1 {
			font-size: 54px;
			font-weight: 400;
			margin-left: 100px;
			font-family: 'Segoe UI', SegoeUI, 'Segoe WP', Tahoma, Arial, sans-serif;
			white-space: nowrap;
		}

		.text2 {
			font-size: 26px;
			font-weight: 400;
			margin-left: 100px;
			font-family: 'Segoe UI', SegoeUI, 'Segoe WP', Tahoma, Arial, sans-serif;
		}
	}

	.body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		margin-top: 50px;

		.text1 {
			background-color: #f5f5f5;
			width: 80%;
			height: 10vh;
			text-align: left;
			display: flex;
			align-items: center;
			font-weight: 600;
			border-radius: 5px;
			// width: 1200px;
			// min-width: 1200px;
		}

		.text2 {
			width: 80%;
			height: 10vh;
			text-align: left;
			display: flex;
			align-items: center;
			font-weight: 600;
			border-bottom: #eeeeee solid 2px;

			.round {
				border-radius: 50%;
				background-color: #008066;
				width: 20px;
				height: 20px;
				margin-left: 100px;
			}
		}
	}
</style>
